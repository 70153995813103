<template>
    <div class="shop_car_steps">
        <div class="item" :class="type === '1' ? 'on' : ''">
            <div class="icon">
                <img src="../../../assets/img/order_pay_menu01.png" v-if="type === '1'" />
                <img src="../../../assets/img/order_pay_menu01_no.png" v-else />
            </div>
            <div class="line"></div>
            <span class="text">支付货款</span>
        </div>

        <div class="item" :class="type === '2' ? 'on' : ''">
            <div class="icon">
                <img src="../../../assets/img/order_pay_menu02.png" v-if="type === '2'" />
                <img src="../../../assets/img/order_pay_menu02_no.png" v-else />
            </div>
            <div class="line"></div>
            <span class="text">付款完成</span>
        </div>
    </div>
</template>

<script>
  export default {
    name: "shopCarMenu",
    props: {
      type: {
        type: String,
        default: ''
      }
    },
    data () {
      return {}
    },
    methods: {},
    components: {}
  }
</script>
